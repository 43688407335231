// create reducer with create slice
import { createSlice } from '@reduxjs/toolkit'

const globalReducer = createSlice({
    name: 'globalReducer',
    initialState: {
        allProducts: [],
        allCategories: [],
        userInfo: {},
        isLoading: false,
    },
    reducers: {
        setAllProducts: (state, action) => {
            state.allProducts = action.payload;
        },
        setAllCategories: (state, action) => {
            state.allCategories = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },


    }
})

export const {
    setAllProducts,
    setAllCategories,
    setUserInfo,
    setIsLoading,
} = globalReducer.actions;
export default globalReducer.reducer;


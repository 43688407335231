
import { useState } from "react";
import HomeHeader from "../../Components/HomeHeader"

const ShippingPolicy = () => {

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 769);
    return (
        <div>
            <HomeHeader pageName={'ShippingPolicy'} />

            <div style={{
                padding: isMobileView ? '10px 10px' : '20px 50px',
                fontSize: isMobileView ? '12px' : '16px',
            }}>
                <div style={{
                    fontSize: isMobileView ? '20px' : '30px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    textAlign: 'center',
                    position: 'relative',
                    fontFamily: 'rockwell_bold',
                }}>
                    Shipping Policy
                </div>

                Thank you for shopping with us! We value your business and are committed to providing a smooth
                and efficient delivery experience. Here's what you can expect when you place an order with us:<br /><br />
                Order Processing Time:<br />
                - Handling Time: Please allow up to 2 business days for us to process and prepare your
                order for shipping. This is to ensure that your items are carefully picked, packed, and
                ready for transit.<br /><br />
                Delivery Time:<br />
                - Standard Shipping: After processing, the estimated delivery time is 5-7 business days.
                However, please note that this is an estimate and actual delivery times may vary due to
                factors outside our control, such as carrier delays or the current situation.<br /><br />
                Shipping Charges:<br />
                - Shipping fees may vary depending on the weight, size, and destination of your order.
                The exact shipping charge will be calculated at checkout.<br /><br />
                Shipping Confirmation and Tracking:<br />
                - Once your order is dispatched, we will send you a shipping confirmation email with a
                tracking number. You can use this number to track your package’s progress.<br /><br />
                Address Changes:<br />
                - We understand that mistakes happen. If you need to change the shipping address after
                placing your order, please contact us as soon as possible. We cannot guarantee the
                change can be made, but we’ll do our best to accommodate your request.<br /><br />
                Incorrect Address and Re-Delivery:<br />
                In the event a package is not delivered due to an incorrect address provided by the
                customer, any charges for the second delivery attempt will be borne by the customer. We
                urge you to double-check your address details at checkout to ensure accurate delivery.<br /><br />
                Delivery Issues:<br />
                - If you encounter any issues with delivery, such as a missing or damaged package,
                please contact us immediately. We are here to help resolve any problems.<br /><br />
                International Shipping:<br />
                - Currently, we only ship domestically. Please check back in the future for updates on
                international shipping options.<br /><br />
                Cancellations:<br />
                - Orders can be cancelled within 24 hours of placement without any charge. Beyond this
                period, a cancellation fee may apply as your order might already be in processing.<br /><br />
                Returns and Exchanges:<br />
                - Please refer to our Return Policy for details on how to return or exchange items.
                We strive to ensure that your order reaches you in perfect condition and within the anticipated
                time frame. However, we appreciate your understanding that sometimes delays can occur due to
                circumstances beyond our control.<br /><br />
                For any further inquiries or assistance, feel free to contact us via WhatsApp (9206822822) or via
                email on fof.turtles@gmail.com. Happy shopping!

            </div>
        </div>
    )
}

export default ShippingPolicy
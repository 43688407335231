import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Modal, Popover } from '@mui/material';

import Cart from '../screens/Cart/Cart';
import { auth } from '../Config/firebase';
import './HomeHeader.scss';
import { logo, logo_black, logo_color } from '../common/images';

const HomeHeader = ({ pageName }) => {

    const [currentPage, setCurrentPage] = useState(pageName);

    const [openCartPopup, setOpenCartPopup] = useState(false);
    const [isAccountPopoverOpen, setIsAccountPopoverOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [mobileView, setMobileView] = useState(window.innerWidth < 769);

    const { allProducts, userInfo } = useSelector(state => state.globalReducer);


    const menuItems = [
        {
            title: "Home",
            link: "#",
            clickFunction: () => {
                navigate('/')
            }
        },
        {
            title: "Our team",
            link: "#",
            clickFunction: () => {
                navigate('/our-team')
            }
        },
        {
            title: "Products",
            link: "#",
            subMenu: [
                {
                    title: "Oral care", link: "#",
                    clickFunction: () => {
                        navigate('/categories-listing?category=oral_care')
                    }
                },
                {
                    title: "Hair care", link: "#",
                    clickFunction: () => {
                        navigate('/categories-listing?category=hair_care')
                    }
                },
                {
                    title: "Drink Wares", link: "#",
                    clickFunction: () => {
                        navigate('/categories-listing?category=drink_wares')
                    }
                },
                {
                    title: "Kitchen Cutlery", link: "#",
                    clickFunction: () => {
                        navigate('/categories-listing?category=kitchen_cutlery')
                    }
                },
                {
                    title: "Personal Care", link: "#",
                    clickFunction: () => {
                        navigate('/categories-listing?category=personal_care')
                    }
                },
                {
                    title: "Sustainable Stationary", link: "#",
                    clickFunction: () => {
                        navigate('/categories-listing?category=sustainable_stationary')
                    }
                },
            ]
        },
        {
            title: "Policies",
            link: "#",
            subMenu: [
                {
                    title: "Terms and Conditions", link: "#",
                    clickFunction: () => {
                        navigate('/terms-and-conditions')
                    }
                },
                {
                    title: "Privacy Policy", link: "#",
                    clickFunction: () => {
                        navigate('/privacy-policy')
                    }
                },
                {
                    title: "Refund Policy", link: "#",
                    clickFunction: () => {
                        navigate('/refund-policy')
                    }
                },
                {
                    title: "Shipping Policy", link: "#",
                    clickFunction: () => {
                        navigate('/shipping-policy')
                    }
                },
            ]
        },



        // {
        //     title: "Page",
        //     link: "#",
        //     subMenu: [
        //         { title: "Team", link: "garden-team.html" },
        //         {
        //             title: "Portfolio",
        //             link: "#",
        //             subMenu: [
        //                 { title: "Portfolio", link: "garden-portfolio.html" },
        //                 { title: "Portfolio 3Column", link: "garden-portfolio-3column.html" },
        //                 { title: "Portfolio 4Column", link: "garden-portfolio-4column.html" },
        //                 { title: "Portfolio Full 3Column", link: "garden-portfolio-full-3column.html" }
        //             ]
        //         },
        //         { title: "Our Clients", link: "garden-clients.html" },
        //         { title: "Pricing Plan", link: "garden-pricing-plan.html" }
        //     ]
        // },
        // {
        //     title: "Blogs",
        //     link: "#",
        //     subMenu: [
        //         { title: "Blog Page", link: "garden-blog.html" },
        //         { title: "Blog Left Sidebar", link: "garden-blog-left-sidebar.html" },
        //         { title: "Blog Right Sidebar", link: "garden-blog-right-sidebar.html" }
        //     ]
        // },
        {
            title: "Contact Us",
            link: "garden-contact.html"
        }
    ];

    useEffect(() => {
        // if scroll is greater than 50px, add class to header
        window.addEventListener('scroll', function () {
            if (document.body.clientHeight > 1000) {

                var header = document.querySelector('.foft_nav_area');
                if (header)
                    header.classList.toggle('sticky', window.scrollY > 50);
            }
        });

    }, [])

    const handleLogout = () => {
        auth.signOut();
        setIsAccountPopoverOpen(false);
        navigate('/login')
    }



    const handleCloseCart = () => {
        console.log('handleIncrement');
        setOpenCartPopup(false);
    };

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    }

    const getBackgroundColor = () => {
        if (currentPage == 'home') {
            return 'transparent';
        } else {
            return 'cadetblue';
        }
    }

    const getPadding = () => {

        if (mobileView) {
            return '15px 10px';
        }

        switch (currentPage) {

            case 'home':
                return '15px 40px';
            case 'product-details':
                return '15px 40px';
            case 'review':
                return '15px 50px';
            case 'my-orders':
                return '15px 120px';
            default:
                return '30px 100px';
        }

    }

    const renderSubMenu = (subMenu) => {
        return (
            <ul className="sub-menu">
                {subMenu.map((item, index) => (
                    <li key={index} className={item.subMenu ? "menu-item-has-children" : ""}>
                        <a
                            onClick={item.clickFunction}
                        >{item.title}</a>
                        {item.subMenu && renderSubMenu(item.subMenu)}
                    </li>
                ))}
            </ul>
        );
    };

    const goToHome = () => {
        navigate('/')
    }


    return (
        <>
            {/* top header */}
            <div class="foft-header-top snipcss-FTDC6">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12 col-lg-9 col-xl-9 col-md-9 col-sm-12">
                            <div class="top-address text-left">
                                <p>
                                    {/* <a href="#"><i class="icofont-home"></i>Dhayari, Pune, Maharashtra, India - 411041.</a> */}
                                    <a href="tel:+919206822822"> <i class="icofont-ui-call"></i>Phone:+91 9206 822 822</a>
                                    <a href="mailto:info@fof-turtles.com"> <i class="icofont-envelope"></i>info@fof-turtles.com
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-lg-3 col-xl-3 col-md-3 col-sm-12 ">
                            <div class="top-right-menu">
                                <ul class="social-icons text-right text_m_center">
                                    <li><a href="https://www.facebook.com/profile.php?id=100085345820697"><i class="icofont-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/friends.of.turtles?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><i class="icofont-instagram"></i></a></li>
                                    <li><a href="https://in.pinterest.com/fofturtles/"><i class="icofont-pinterest"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/friends-of-turtles/?viewAsMember=true"><i class="icofont-linkedin"></i></a></li>
                                    <li><a href="https://www.youtube.com/@FriendsofTurtles"><i class="icofont-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* main header */}

            <div className="foft_nav_area scroll_fixed postfix snipcss-o3hFQ">
                <div className="container-fluid">
                    <div className="row logo-left">
                        <div className="col-md-3 col-sm-3 col-xs-4">
                            <div className="logo">
                                <div className="main_logo" title="Friends of Turtles" onClick={() => goToHome()}>
                                    <img
                                        className="logo_img_main"
                                        src={logo_black}
                                        alt="Friends of Turtles"
                                        style={{
                                            width: '100px',
                                            objectFit: 'cover',
                                        }}
                                    />

                                    <img
                                        className="logo_img_fixed"
                                        src={logo_black}
                                        alt="foft"
                                        style={{
                                            width: '100px',
                                        }}
                                    />
                                    <div className="logo-text logo_text_anim">
                                        friends of <br></br><span className='logo-sub-text'>turtles</span>
                                    </div>

                                    {/* <div class="FP6oS"></div> */}
                                </div>


                            </div>
                        </div>
                        <div className="col-md-9 col-sm-9 col-xs-8">
                            <div className="tx_mmenu_together">
                                <nav className="foft_menu">
                                    <ul className="sub-menu">
                                        {menuItems.map((item, index) => (
                                            <li key={index} className={item.subMenu ? "menu-item-has-children" : ""}
                                            >
                                                <a onClick={item.clickFunction}>{item.title}</a>
                                                {item.subMenu && renderSubMenu(item.subMenu)}
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                                <div className='my_profile_wrapper'>
                                    <ShoppingCartIcon className='header_icons' onClick={() => auth.currentUser && setOpenCartPopup(true)}
                                        style={{
                                            opacity: auth.currentUser ? 1 : 0.5,
                                            cursor: auth.currentUser ? 'pointer' : 'not-allowed',
                                            fontSize: mobileView ? '30px' : '40px',
                                        }}
                                    />

                                    <div className='account_wrapper'
                                        onClick={() => auth.currentUser ? setIsAccountPopoverOpen(!isAccountPopoverOpen) : navigate('/login')}
                                    >
                                        <AccountCircleIcon className='header_icons'
                                            style={{
                                                fontSize: mobileView ? '30px' : '40px',
                                            }}
                                        />
                                        {
                                            auth.currentUser ?
                                                <div className='user_name'>{userInfo?.first_name} {!mobileView && (userInfo?.last_name)}</div>
                                                :
                                                <div className='user_name' >Login</div>
                                        }

                                        <>
                                            {
                                                isAccountPopoverOpen &&
                                                <div className='account_popover'>
                                                    <div className='menu_items' onClick={() => navigate('/my-orders')}>My Orders</div>
                                                    <div className='horizontal_line'></div>
                                                    <div className='menu_items' onClick={() => handleLogout()}>Logout</div>

                                                </div>
                                            }
                                        </>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* openCartPopup */}

            <Modal open={openCartPopup}
                onClose={() => setOpenCartPopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEscapeKeyDown={true}
                BackdropProps={{ onClick: handleBackdropClick }}
            >
                <>
                    {/* <BlurredBackground /> */}
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        width: mobileView ? '100%' : '450px', // Adjust the width as needed
                        backgroundColor: 'white', // Background color for the fixed section
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // Optional: add a shadow for better visibility
                        overflowY: 'auto',
                    }}>
                        <Cart onCloseCart={handleCloseCart} />
                    </div>
                </>
            </Modal>
        </>
    )
}

export default HomeHeader
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import 'firebase/auth';
import { getStorage } from "firebase/storage";
// import auth
import { getAuth } from "firebase/auth";


// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyC_88-52qXyDicix4e06Xm6hKbZ_JiECag",
//     authDomain: "fir-2755c.firebaseapp.com",
//     databaseURL: "https://fir-2755c.firebaseio.com",
//     projectId: "fir-2755c",
//     storageBucket: "fir-2755c.appspot.com",
//     messagingSenderId: "1082164236016",
//     appId: "1:1082164236016:web:71e9d423bcedd15548bc35"
// };

const firebaseConfig = {
    apiKey: "AIzaSyD-afE9fmXRiaiIsV-sW4dgukzeC5E-qyU",
    authDomain: "foft-prod.firebaseapp.com",
    projectId: "foft-prod",
    storageBucket: "foft-prod.appspot.com",
    messagingSenderId: "132712234289",
    appId: "1:132712234289:web:a74c73bb71e0e46b960410",
    measurementId: "G-L17HJPCD32"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const storage = getStorage(app);

// auth
export const auth = getAuth(app);
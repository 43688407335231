
import { useState } from "react";
import HomeHeader from "../../Components/HomeHeader"

const PrivacyPolicy = () => {

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 769);
    return (
        <div>
            <HomeHeader pageName={'PrivacyPolicy'} />

            <div style={{
                padding: isMobileView ? '10px 10px' : '20px 50px',
                fontSize: isMobileView ? '12px' : '16px',
            }}>
                <div style={{
                    fontSize: isMobileView ? '20px' : '30px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    textAlign: 'center',
                    position: 'relative',
                    fontFamily: 'rockwell_bold',
                }}>
                    Privacy Policy
                </div>

                We (Friends of Turtles) prioritize the privacy and protection of our customers' information. By engaging in
                transactions with Friends of Turtles, you acknowledge and agree to our privacy practices outlined below:<br /><br />
                Collection and Storage of Information:<br />
                We collect and store information provided by customers during the ordering process, including but
                not limited to names, addresses, and contact details. This data is essential for order fulfillment and
                customer communication.<br /><br />
                What do we do with your information?<br />
                When you purchase something from our store, as part of the buying and selling process, we collect
                the personal information you give us such as your name, address and email address.
                Email marketing (if applicable): With your permission, we may send you emails about our store,
                new products and other updates.<br /><br />
                How do you get my consent?<br />
                When you provide us with personal information to complete a transaction, verify your credit card,
                place an order, arrange for a delivery or return a purchase, we imply that you consent to our
                collecting it and using it for that specific reason only.<br />
                If we ask for your personal information for a secondary reason, like marketing, we will either ask
                you directly for your expressed consent, or provide you with an opportunity to say no.<br /><br />
                How do I withdraw my consent?<br />
                If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for
                the continued collection, use or disclosure of your information, at anytime, by contacting us at
                fof.turtles@gmail.com or mailing us at: fof.turtles@gmail.com<br /><br />
                Purpose of Information Usage:<br />
                The information collected is solely utilized for order processing, communication, and internal
                record-keeping. We may also use this information to improve our products, services, and overall
                customer experience.<br /><br />
                Security Measures:<br />
                We implement industry-standard security measures to safeguard the collected information against
                unauthorized access, disclosure, alteration, or destruction. However, please note that no online
                method of transmission or storage is 100% secure, and absolute security cannot be guaranteed.<br /><br />
                No Third-Party Sharing:<br />
                Under no circumstances will we share, sell, or trade customer information with third parties for
                marketing or any other purposes. We are committed to maintaining the trust and confidence of our
                customers.<br /><br />
                Third-party services<br />
                In general, the third-party providers used by us will only collect, use and disclose your information
                to the extent necessary to allow them to perform the services they provide to us.<br />
                However, certain third-party service providers, such as payment gateways and other payment
                transaction processors, have their own privacy policies in respect to the information we are
                required to provide to them for your purchase-related transactions.<br />
                For these providers, we recommend that you read their privacy policies so you can understand the
                manner in which your personal information will be handled by these providers.<br />
                In particular, remember that certain providers may be located in or have facilities that are located a
                different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves
                the services of a third-party service provider, then your information may become subject to the laws
                of the jurisdiction(s) in which that service provider or its facilities are located.<br />
                Once you leave our store’s website or are redirected to a third-party website or application, you are
                no longer governed by this Privacy Policy or our website’s Terms of Service.<br /><br />
                Links<br />
                When you click on links on our store, they may direct you away from our site. We are not
                responsible for the privacy practices of other sites and encourage you to read their privacy
                statements.<br /><br />
                Cookies<br />
                We use cookies to maintain session of your user. It is not used to personally identify you on other
                websites.<br /><br />
                Age of consent<br />
                By using this site, you represent that you are at least the age of majority in your state or province of
                residence, or that you are the age of majority in your state or province of residence and you have
                given us your consent to allow any of your minor dependents to use this site.<br /><br />
                Legal Requirements:<br />
                We may disclose customer information if required to do so by law or in response to valid legal
                requests, such as court orders or government regulations.<br /><br />
                Customer Consent:<br />
                By using our services and providing your information, you consent to the collection, storage, and
                usage of your data as outlined in this privacy clause.<br /><br />
                Payment<br />
                We use Razorpay for processing payments. We/Razorpay do not store your card data on their
                servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS)
                when processing payment. Your purchase transaction data is only used as long as is necessary to
                complete your purchase transaction. After that is complete, your purchase transaction information is
                not saved.<br />
                Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and
                Discover.<br />
                PCI-DSS requirements help ensure the secure handling of credit card information by our store and
                its service providers.<br />
                For more insight, you may also want to read terms and conditions of razorpay on<br />
                https://razorpay.com<br /><br />
                Updates to Privacy Policy:<br />
                We reserve the right to update our privacy policy periodically. Any changes will be reflected on our
                website, and continued use of our services implies acceptance of the updated terms.
                Your privacy is of utmost importance to us. If you have any concerns or questions regarding the
                privacy of your information, please contact us at +91 9206 822 822. We appreciate your trust in
                Friends of Turtles.<br /><br />
                Last Updated: 1 January 2024<br />
                Thank you for choosing Friends of Turtles. We appreciate your business and look forward to serving you.

            </div>
        </div>
    )
}

export default PrivacyPolicy
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditIcon from '@mui/icons-material/Edit';
import './Review.scss';
import { product1 } from '../../common/images';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { db } from '../../Config/firebase';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DoneIcon from '@mui/icons-material/Done';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { Modal } from '@mui/material';
import Addresses from '../Addresses/Addresses';
import { useDispatch, useSelector } from 'react-redux';
import Payment from '../Payment/Payment';
import HomeHeader from '../../Components/HomeHeader';
import { setIsLoading } from '../../Redux/globalReducer';
import { toast } from 'react-toastify';

const Review = () => {

    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.globalReducer);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [routeData, setRouteData] = useState([]);

    const [orderProducts, setOrderProducts] = useState([]);
    const [minimumQuantity, setMinimumQuantity] = useState(2);
    const [deliveryFee, setDeliveryFee] = useState(40);

    const [openAddressPopup, setOpenAddressPopup] = useState(false);

    const [deleveryAddress, setDeleveryAddress] = useState({});

    const [enablePayment, setEnablePayment] = useState(false);

    const [allOrderDetailsForPayment, setAllOrderDetailsForPayment] = useState({});

    const [mobileView, setMobileView] = useState(window.innerWidth < 769);

    useEffect(() => {
        if (searchParams) {
            const details = searchParams.get('details');
            setRouteData(JSON.parse(details));
        }
    }, [searchParams]);

    useEffect(() => {
        loadScript('https://checkout.razorpay.com/v1/checkout.js');
    }, []);


    const loadScript = src => new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            console.log('razorpay loaded successfully');
            resolve(true);
        };
        script.onerror = () => {
            console.log('error in loading razorpay');
            resolve(false);
        };
        document.body.appendChild(script);
    });

    useEffect(() => {
        if (routeData.length > 0) {
            getProductsData();
        }
    }, [routeData])

    useEffect(() => {
        // choose default address
        if (userInfo?.addresses?.length > 0) {
            const defaultAddress = userInfo.addresses.find((address) => address.isDefault);
            if (defaultAddress) {
                setDeleveryAddress(defaultAddress);
            } else {
                setDeleveryAddress(userInfo.addresses[0]);
            }
        }
    }, [userInfo])

    const getProductsData = async () => {
        dispatch(setIsLoading(true));

        let tempData = [];

        for (const product of routeData) {
            const productId = product.id;
            const quantity = product.quantity;

            // get product details from firestore

            const docRef = getDoc(doc(db, "Products", productId));
            const docSnap = await docRef;
            if (docSnap.exists()) {
                const data = docSnap.data();
                tempData.push({
                    ...data,
                    quantity
                })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }

        }

        setOrderProducts(tempData);
        console.log('tempData', tempData);

        setTimeout(() => {
            dispatch(setIsLoading(false));
        }, 2000);

    }

    const handleBackClick = () => {
        navigate(-1);
    }

    const handleQuantityChange = (product, type) => {
        let obj = {
            ...product
        }
        if (type === 'plus') {
            obj.quantity = obj.quantity + 1;
        } else {
            if (obj.quantity > 1) {
                obj.quantity = obj.quantity - 1;
            }
        }

        const index = orderProducts.findIndex((item) => item.id === obj.id);

        let tempData = [...orderProducts];
        tempData[index] = obj;
        setOrderProducts(tempData);

    }

    const getTotalMRP = () => {
        let total = 0;
        orderProducts.forEach((product) => {
            total = total + (product.actual_price * product.quantity);
        })
        return total;
    }

    const getTotalDiscount = () => {
        let total = 0;
        orderProducts.forEach((product) => {
            total = total + ((product.actual_price - product.sell_price) * product.quantity);
        })
        return total;
    }

    const getTotalAmount = () => {
        let total = 0;
        orderProducts.forEach((product) => {
            total = total + (product.sell_price * product.quantity);
        })
        if (isDeleveryFree()) {
            return total;
        } else {
            return total + deliveryFee;
        }
    }

    const isDeleveryFree = () => {
        // make free if total order value is greater than or equal to 200
        let totalValue = 0;
        orderProducts.forEach((product) => {
            totalValue = totalValue + (product.sell_price * product.quantity);
        })

        if (totalValue >= 200) {
            return true;
        } else {
            return false;
        }
    }

    const handleBackdropClick = () => {
        setOpenAddressPopup(true);
    }

    const handleCloseAddresses = () => {
        setOpenAddressPopup(false);
    }

    const selectDeleveryAddress = (address) => {
        setDeleveryAddress(address);
        setOpenAddressPopup(false);
    }

    const handleAddNewAddressBtn = () => {
        setOpenAddressPopup(true);
    }

    const getAddressString = (address) => {
        let addressString = '';
        addressString += address.address + ', ';
        addressString += address.locality + ', ';
        addressString += address.cityDistrictTown + ', ';
        addressString += address.state + ', ';
        addressString += address.pincode + ', ';

        if (address.landmark) {
            addressString += address.landmark + ', ';
        } else if (address.alternatePhone) {
            addressString += address.alternatePhone + ', ';
        }

        // remove last comma
        addressString = addressString.substring(0, addressString.length - 2);

        return addressString;
    }

    const handleMakePaymentBtn = () => {
        // check if delevery address is selected
        if (!deleveryAddress?.id) {
            toast.error('Please select delevery address');
            return;
        }

        handlePayment();
    }

    const handlePayment = async () => {

        // delete images from orderProducts
        let tempOrderProducts = JSON.parse(JSON.stringify(orderProducts));
        tempOrderProducts = tempOrderProducts.map((product) => {
            return {
                id: product.id,
                actual_price: product.actual_price,
                sell_price: product.sell_price,
                quantity: product.quantity,
                name: product.name,
            }
        })

        let allOrderDetails = {};
        allOrderDetails['orderProducts'] = tempOrderProducts;
        allOrderDetails['deleveryAddress'] = deleveryAddress;
        allOrderDetails['totalMRP'] = getTotalMRP();
        allOrderDetails['totalDiscount'] = getTotalDiscount();
        allOrderDetails['totalAmount'] = getTotalAmount();
        allOrderDetails['isDeleveryFree'] = isDeleveryFree();

        console.log('allOrderDetails', allOrderDetails);

        setAllOrderDetailsForPayment(allOrderDetails);
        setEnablePayment(true);

        setTimeout(() => {
            setEnablePayment(false);
        }, 5000);
    };

    const getStepsComponent = () => {

        return (
            <div className='order_steps_wrapper'>
                <div className='order_step complete'>
                    <div className='order_step_number'>
                        <DoneIcon className='tick_icon' />
                    </div>
                    <div className='order_step_title'>My Cart</div>
                </div>
                <div className='horizontal_line_wrapper'>
                    <div className='horizontal_line complete'></div>
                </div>
                <div className='order_step active'>
                    <div className='order_step_number'>2</div>
                    <div className='order_step_title'>Order Review</div>
                </div>
                <div className='horizontal_line_wrapper '>
                    <div className='horizontal_line disabled'></div>
                </div>
                <div className='order_step disabled'>
                    <div className='order_step_number'>3</div>
                    <div className='order_step_title'>Payment</div>
                </div>
            </div>
        )

    }

    const handleSignInToContinue = () => {
        // send to login page with redirect url

        // capture current url and remove origin
        const currentUrl = window.location.href;
        const redirectUrl = currentUrl.replace(window.location.origin, '');
        console.log('redirectUrl', redirectUrl);
        navigate('/login?redirectUrl=' + redirectUrl);

    }


    return (
        <>
            <HomeHeader pageName={'review'} />
            <div className='order_review_page'>
                <div className="flex-start flex-gap-10" style={{}}>
                    <KeyboardBackspaceIcon className='c-pointer' style={{ fontSize: mobileView ? 24 : 30 }}
                        onClick={() => handleBackClick()}
                    />
                    <div style={{
                        fontSize: mobileView ? '20px' : '24px',
                        fontWeight: 'bold',
                    }}>Order Review</div>
                </div>

                {
                    mobileView &&
                    getStepsComponent()
                }

                <div className='order_review_wrapper'>
                    <div className='order_left_sec'>
                        <div className='address_wrapper'>
                            <div className='address_title'>Delivery Address</div>
                            <div className='horizontal_line'></div>
                            {
                                userInfo?.user_id ?
                                    <>
                                        {
                                            deleveryAddress?.id ?

                                                <div className='address_details'>
                                                    <div className='address_name'>{deleveryAddress?.fullName}
                                                    </div>
                                                    <div className='address_text'>{getAddressString(deleveryAddress)}
                                                    </div>
                                                    <div className='address_pincode'>
                                                        <span>Pincode </span>{deleveryAddress?.pincode}
                                                    </div>
                                                    <div className='address_mobile'>
                                                        <span>Mobile </span>+91 {deleveryAddress?.mobile}
                                                    </div>

                                                    <div className='address_edit_wrapper'>
                                                        <EditIcon className='c-pointer' style={{ fontSize: 25 }}
                                                            onClick={() => setOpenAddressPopup(true)}
                                                        />
                                                    </div>

                                                </div>
                                                :
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: '10px',
                                                    marginTop: '20px',
                                                }}>
                                                    <div style={{
                                                        backgroundColor: '#ffffff',
                                                        color: 'white',
                                                        padding: '5px 20px',
                                                        borderRadius: '20px',
                                                        cursor: 'pointer',
                                                        width: 'max-content',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '10px',
                                                        border: '1px solid #13c519',
                                                    }}
                                                        onClick={() => handleAddNewAddressBtn()}
                                                    >
                                                        <AddIcon style={{
                                                            color: '#13c519',
                                                            fontSize: '30px',
                                                        }} />
                                                        <div style={{
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                            color: '#000',
                                                        }}
                                                        >
                                                            Add New Address
                                                        </div>

                                                    </div>
                                                </div>
                                        }

                                    </>

                                    :

                                    // sign in to continue

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        marginTop: '20px',
                                    }}>
                                        <div style={{
                                            backgroundColor: '#13c519',
                                            color: 'white',
                                            padding: '5px 20px',
                                            borderRadius: '20px',
                                            cursor: 'pointer',
                                            width: 'max-content',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '10px',
                                            border: '1px solid #13c519',
                                        }}
                                            onClick={() => handleSignInToContinue()}
                                        >

                                            <div style={{
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                color: '#fff',
                                            }}
                                            >
                                                Sign in to continue
                                            </div>

                                        </div>
                                    </div>

                            }
                        </div>

                        <div className='order_products_list_wrapper'>
                            <div className='order_products_title'>
                                Products ({orderProducts.length})
                            </div>
                            {
                                orderProducts?.map((product, index) => {
                                    return (
                                        <div key={index}>
                                            <div className='horizontal_line' style={{ marginTop: 10 }}></div>

                                            <div className='product_details_wrapper'>
                                                <div className='product_image'>
                                                    <img src={product.images[0]} />
                                                </div>
                                                <div className='product_details'>
                                                    <div className='product_name'>
                                                        {product.name}
                                                    </div>
                                                    <div className='product_price'>
                                                        <div className='discount_price'>&#8377;{product?.sell_price}</div>
                                                        <div className='actual_price'>&#8377;{product?.actual_price}</div>
                                                    </div>
                                                    <div className='you_sava_box'>
                                                        You have save &#8377; {product.actual_price - product?.sell_price}
                                                    </div>
                                                    <div className='sold_by'>
                                                        Sold By: <span className='bold'>Friends of Turtles</span>
                                                    </div>
                                                </div>

                                                <div className='plus_minus_wrapper'>
                                                    <div className='plus' onClick={() => handleQuantityChange(product, 'minus')}><RemoveIcon /></div>
                                                    <div className='quantity' style={{
                                                        marginTop: '5px'
                                                    }}>{product.quantity}</div>
                                                    <div className='minus' onClick={() => handleQuantityChange(product, 'plus')}><AddIcon /></div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div className='order_right_sec'>

                        {
                            !mobileView &&
                            getStepsComponent()
                        }

                        <div className='order_summary_wrapper'>
                            <div className='free_delivery_banner'
                                style={{
                                    backgroundColor: isDeleveryFree() ? '#13c519' : '#f9a109',
                                }}
                            >
                                {
                                    isDeleveryFree() && <CelebrationIcon className='celebration_icon' />
                                }
                                <div className='banner_text'>
                                    {
                                        isDeleveryFree() ?
                                            'Yah! You get FREE delivery on this order'
                                            :
                                            'Make total order above 200 to get FREE delivery'
                                    }
                                </div>
                            </div>
                            <div className='payment_details'>

                                <div className='payment_title'>
                                    Payment Details
                                </div>
                                <div className='payment_details_row'>
                                    <div className='payment_details_title'>Total MRP</div>
                                    <div className='payment_details_value'>&#8377; {getTotalMRP()}</div>
                                </div>
                                <div className='horizontal_line'></div>
                                <div className='payment_details_row'>
                                    <div className='payment_details_title'>Discount</div>
                                    <div className='payment_details_value bold green'>- &#8377;{getTotalDiscount()}</div>
                                </div>
                                <div className='horizontal_line'></div>
                                <div className='payment_details_row'>
                                    <div className='payment_details_title'>Delivery Fee</div>
                                    {
                                        isDeleveryFree() ?
                                            <div className='payment_details_value bold green'>
                                                Free
                                                <span style={{
                                                    color: '#9A9A9A',
                                                    fontSize: '14px',
                                                    marginLeft: '10px',
                                                    textDecoration: 'line-through'
                                                }}>&#8377;40.00</span>
                                            </div>
                                            :
                                            <div className='payment_details_value bold green'>
                                                + &#8377;40.00
                                                <span style={{
                                                    color: '#9A9A9A',
                                                    fontSize: '14px',
                                                    marginLeft: '10px',
                                                    textDecoration: 'line-through'
                                                }}>Free</span>
                                            </div>
                                    }
                                </div>
                                <div className='horizontal_line'></div>
                                <div className='payment_details_row'>
                                    <div className='payment_details_title bold green'>Total Amount</div>
                                    <div className='payment_details_value bold green'>&#8377; {getTotalAmount()}</div>
                                </div>
                            </div>

                        </div>
                        {
                            userInfo?.user_id ?
                                <div className='make_payment_btn'
                                    onClick={() => handleMakePaymentBtn()}
                                >
                                    Make Payment
                                </div>
                                :
                                // sign in to continue
                                <div className='make_payment_btn'
                                    onClick={() => handleSignInToContinue()}
                                >
                                    Sign In to Continue
                                </div>

                        }

                    </div>


                </div>

                <Modal open={openAddressPopup}
                    onClose={() => setOpenAddressPopup(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableEscapeKeyDown={true}
                    BackdropProps={{ onClick: handleBackdropClick }}
                >
                    <>
                        {/* <BlurredBackground /> */}
                        <div style={{
                            backgroundColor: '#f2f4fd',
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            width: mobileView ? '100%' : '450px', // Adjust the width as needed
                            // backgroundColor: 'white', // Background color for the fixed section
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)', // Optional: add a shadow for better visibility
                            overflowY: 'auto',
                        }}>
                            <Addresses onCloseAddresses={handleCloseAddresses} selectDeleveryAddress={selectDeleveryAddress} />
                        </div>
                    </>
                </Modal>

                {
                    (allOrderDetailsForPayment && enablePayment) &&

                    <Payment allOrderDetailsForPayment={allOrderDetailsForPayment} />

                }


            </div >
        </>
    );
}

export default Review;
import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, query, where, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../Config/firebase';
import './Cart.scss'; // Your Cart styles
import { useSelector } from 'react-redux';
import { product1 } from '../../common/images';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const Cart = ({ onCloseCart }) => {
    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState([]);
    const [productName, setProductName] = useState('');

    const { userInfo } = useSelector(state => state.globalReducer);
    const [mobileView, setMobileView] = useState(window.innerWidth < 769);

    // Fetch cart items from Firestore
    useEffect(() => {
        const fetchCartItems = async () => {
            const cartCollection = collection(db, 'Users', userInfo?.user_id, 'Cart');
            const q = query(cartCollection);

            try {
                const querySnapshot = await getDocs(q);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() });
                });
                setCartItems(items);

                console.log('Cart items: ', items);
            } catch (error) {
                console.error('Error fetching cart items: ', error);
            }
        };

        if (userInfo?.user_id) {
            fetchCartItems();
        }

    }, [userInfo.user_id]);

    const handleQuantityChange = (item, type) => {
        console.log('item', item);
        console.log('type', type);

        let quantity = item.quantity;
        if (type === 'plus') {
            quantity++;
        } else {
            quantity--;
        }

        if (quantity > 0) {
            const docRef = doc(db, 'Users', userInfo?.user_id, 'Cart', item.id);
            setDoc(docRef, {
                ...item,
                quantity: quantity,
            }).then(() => {
                console.log('Document successfully written!');
                updateCartItems(item, quantity);
            }).catch((error) => {
                console.error("Error writing document: ", error);
            });

        } else {
            removeItemFromCart(item);
        }

    }

    const updateCartItems = (item, quantity) => {
        const updatedCartItems = cartItems.map((cartItem) => {
            if (cartItem.id === item.id) {
                return {
                    ...cartItem,
                    quantity: quantity,
                };
            }
            return cartItem;
        });

        setCartItems(updatedCartItems);
    }

    const removeItemFromCart = (item) => {
        const docRef = doc(db, 'Users', userInfo?.user_id, 'Cart', item.id);
        deleteDoc(docRef).then(() => {
            console.log('Document successfully deleted!');
        }).catch((error) => {
            console.error("Error removing document: ", error);
        })

        const updatedCartItems = cartItems.filter((cartItem) => {
            return cartItem.id !== item.id;
        })

        setCartItems(updatedCartItems);

    }

    const getTotalPriceCart = () => {
        let totalPrice = 0;
        cartItems.forEach((item) => {
            totalPrice += item.sell_price * item.quantity;
        })
        return totalPrice;
    }

    const handleCheckout = () => {

        const items = cartItems.map((item) => {
            return {
                id: item.id,
                quantity: item.quantity,
            }
        })
        console.log('cartItems', items);

        navigate('/review?details=' + JSON.stringify(items));
        onCloseCart();
    }

    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: mobileView ? '10px 10px' : '10px 20px',
                position: 'sticky',
                top: 0,
                width: '100%',
                height: '60px',
                backgroundColor: 'white',
                borderBottom: '1px solid #e0e0e0',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                zIndex: 1,

            }}>
                <div onClick={onCloseCart}>
                    <ArrowBackIcon style={{
                        cursor: 'pointer',

                    }} />
                </div>
                <div style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginLeft: '10px',
                }}>
                    My cart
                </div>
            </div>
            <div style={{
                padding: mobileView ? '10px 10px' : '20px 15px',
                height: '100%',
                overflowY: 'scroll',
            }}>
                {
                    cartItems?.map((item, index) => {
                        return (
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: mobileView ? '10px' : '15px',
                                padding: '10px',
                                boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)',
                                marginBottom: '20px',
                                backgroundColor: '#ffffff',
                                borderRadius: '10px',
                                border: '1px solid #e0e0e0',
                            }}>
                                <div>
                                    <img src={item.images ? item.images[0] : ""} alt={item.name} style={{
                                        width: mobileView ? 90 : 100,
                                        height: mobileView ? 90 : 100,
                                        objectFit: 'contain',
                                        borderRadius: '10px',

                                    }} />
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                }}>
                                    <div style={{
                                        fontSize: '16px',
                                        width: '150px',
                                        overflow: 'hidden',
                                        whiteSpace: 'pre-wrap',
                                    }}>{item.name}</div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginTop: '10px',
                                    }}>
                                        <div style={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            width: mobileView ? '70px' : '100px',
                                        }}>
                                            {item.sell_price} Rs
                                        </div>
                                        <div>
                                            <div className='plus_minus_wrapper' style={{

                                                gap: mobileView ? '5px' : '10px',
                                            }}>
                                                <div className='plus' onClick={() => handleQuantityChange(item, 'minus')}><RemoveIcon /></div>
                                                <div className='quantity' style={{
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                    width: mobileView ? '15px' : '20px',
                                                }}>{item.quantity}</div>
                                                <div className='minus' onClick={() => handleQuantityChange(item, 'plus')}><AddIcon /></div>
                                            </div>
                                        </div>
                                        <div>
                                            <DeleteIcon onClick={() => removeItemFromCart(item)} color='#fff' />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )
                    }
                    )
                }
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 20px',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '80px',
                backgroundColor: 'white',
                borderTop: '1px solid #e0e0e0',
                boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <ShoppingCartIcon fontSize='large' />
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        marginLeft: '10px',

                    }}>
                        <div>{cartItems.length} items</div>
                        <div style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}>₹ {getTotalPriceCart()}</div>
                    </div>
                </div>
                <div>
                    <button style={{
                        backgroundColor: '#f9a109',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        border: 'none',
                        outline: 'none',
                        cursor: 'pointer',

                    }}
                        onClick={() => handleCheckout()}
                        disabled={cartItems.length === 0}
                    >CHECKOUT</button>
                </div>
            </div>


            {/* empty cart message */}


            {
                cartItems.length === 0 &&
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',

                }}>
                    <div style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        fontFamily: 'sans-serif',

                    }}>Your cart is empty</div>
                </div>
            }

        </div >
    );
};

export default Cart;


import React, { useEffect, useState } from 'react'
import './Addresses.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../../Redux/globalReducer';

const Addresses = ({ onCloseAddresses, selectDeleveryAddress }) => {

    const dispatch = useDispatch();

    const { userInfo } = useSelector(state => state.globalReducer);

    const [allAddresses, setAllAddresses] = useState([]);

    const [address, setAddress] = useState({
        id: new Date().getTime(),
        fullName: '',
        mobile: '',
        pincode: '',
        locality: '',
        address: '',
        cityDistrictTown: '',
        state: '',
        landmark: '',
        alternatePhone: '',
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
    });

    const [showAddNewAddress, setShowAddNewAddress] = useState(false);

    useEffect(() => {
        console.log('userInfo', userInfo);
        if (userInfo.addresses) {
            setAllAddresses(userInfo.addresses ? userInfo.addresses : []);
        }
    }, [userInfo])


    const onCloseAddress = () => {
        onCloseAddresses();
    }

    const handleOnChangeAddress = (e, type) => {
        setAddress({
            ...address,
            [type]: e.target.value,
        })
    }

    const validateAddress = () => {
        let requiredFields = ['fullName', 'mobile', 'pincode', 'locality', 'address', 'cityDistrictTown', 'state'];
        let valid = true;
        for (let i = 0; i < requiredFields.length; i++) {
            if (address[requiredFields[i]] === '') {
                valid = false;
                break;
            }
        }

        if (valid) {

            // add address to db
            return true;

        } else {
            return false;
        }
    }

    const saveAddress = () => {
        console.log('address', address)
        const validate = validateAddress();

        if (validate) {

            let addresses = userInfo.addresses ? [...userInfo.addresses] : [];

            console.log('addresses', addresses);
            // check if address already exists
            let addressExists = false;

            for (let i = 0; i < addresses.length; i++) {
                if (addresses[i].id === address.id) {
                    addressExists = true;
                    break;
                }
            }

            console.log('addressExists', addressExists);

            // return
            if (addressExists) {
                // update address
                for (let i = 0; i < addresses.length; i++) {
                    if (addresses[i].id === address.id) {
                        addresses[i] = { ...address };
                        break;
                    }
                }
            } else {
                // add address
                addresses.push(address);
            }

            updateDoc(doc(db, "Users", auth.currentUser.uid), {
                addresses: addresses,
            }).then(() => {
                console.log('address saved')

                toast.success('Address saved successfully', {
                    autoClose: 2000,
                });

                setShowAddNewAddress(false);
                dispatch(setUserInfo({
                    ...userInfo,
                    addresses: addresses,
                }))

            }).catch((error) => {
                console.log('error', error)
            })
        } else {
            console.log('not valid')
            toast.error('Please fill all the required fields', {
                autoClose: 1500,
            });
        }
    }

    const getAddressString = (address) => {
        let addressString = '';
        addressString += address.address + ', ';
        addressString += address.locality + ', ';
        addressString += address.cityDistrictTown + ', ';
        addressString += address.state + ', ';
        addressString += address.pincode + ', ';

        if (address.landmark) {
            addressString += address.landmark + ', ';
        } else if (address.alternatePhone) {
            addressString += address.alternatePhone + ', ';
        }

        // remove last comma
        addressString = addressString.substring(0, addressString.length - 2);

        return addressString;
    }

    const handleAddNewAddressBtn = () => {
        // reset address
        setAddress({
            id: new Date().getTime(),
            fullName: '',
            mobile: '',
            pincode: '',
            locality: '',
            address: '',
            cityDistrictTown: '',
            state: '',
            landmark: '',
            alternatePhone: '',
            createdAt: new Date().getTime(),
            updatedAt: new Date().getTime(),
        })
        setShowAddNewAddress(true);
    }

    const handleDeliverHere = (addr) => {
        // set address as isDefault in db

        let addresses = userInfo.addresses ? [...userInfo.addresses] : [];
        addresses = JSON.parse(JSON.stringify(addresses));
        addresses.map((address) => {
            if (address.id === addr.id) {
                address.isDefault = true;
            } else {
                address.isDefault = false;
            }
        })

        updateDoc(doc(db, "Users", auth.currentUser.uid), {
            addresses: addresses,
        }).then(() => {
            console.log('address saved')
            selectDeleveryAddress(addr);

            toast.success('Address select as default address', {
                autoClose: 2000,
            });

            setShowAddNewAddress(false);
            dispatch(setUserInfo({
                ...userInfo,
                addresses: addresses,
            }))

        }).catch((error) => {
            console.log('error', error)
        })
    }

    const handleDeleteAddress = (addr) => {
        // set address as isDefault in db

        let addresses = userInfo.addresses ? [...userInfo.addresses] : [];
        addresses = JSON.parse(JSON.stringify(addresses));
        addresses = addresses.filter((address) => address.id !== addr.id);

        updateDoc(doc(db, "Users", auth.currentUser.uid), {
            addresses: addresses,
        }).then(() => {
            console.log('address saved')

            toast.success('Address deleted successfully', {
                autoClose: 2000,
            });

            setShowAddNewAddress(false);
            dispatch(setUserInfo({
                ...userInfo,
                addresses: addresses,
            }))

        }).catch((error) => {
            console.log('error', error)
        })
    }



    return (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '10px 10px',
                position: 'sticky',
                top: 0,
                width: '100%',
                height: '60px',
                backgroundColor: 'white',
                borderBottom: '1px solid #e0e0e0',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                zIndex: 1,

            }}>
                <div onClick={onCloseAddress}>
                    <ArrowBackIcon style={{
                        cursor: 'pointer',
                    }} />
                </div>
                <div style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    marginLeft: '10px',
                }}>
                    Select Address
                </div>
            </div>
            <div style={{
                padding: '20px 15px',
                height: '100%',
                overflowY: 'scroll',
            }}>

                {/* add new address btn */}

                {
                    !showAddNewAddress &&
                    <>
                        <div style={{
                            backgroundColor: '#fff',
                            color: 'white',
                            padding: '5px 20px',
                            borderRadius: '20px',
                            cursor: 'pointer',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            border: '1px solid #13c519',
                        }}
                            onClick={() => handleAddNewAddressBtn()}
                        >
                            <AddIcon style={{
                                color: '#13c519',
                                fontSize: '30px',
                            }} />
                            <div style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#000',
                            }}
                            >
                                Add New Address
                            </div>

                        </div>

                        <div>
                            <div style={{
                            }}>
                                <div style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    marginTop: '20px',
                                }}>Saved Addresses</div>

                                <div style={{
                                    fontSize: '14px',
                                }}>Select the address where you want to deliver your product</div>
                            </div>

                            {
                                allAddresses?.map((address, index) => (
                                    <div key={index} style={{
                                        padding: 20,
                                        backgroundColor: 'white',
                                        borderRadius: 20,
                                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                        border: '1px solid #000',
                                        marginTop: '20px',
                                        position: 'relative',
                                    }}>
                                        {/* name, address string, mobile no, diliver here btn and edit icon absolute right icon */}

                                        <div style={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}>{address.fullName}</div>
                                        <div style={{
                                            fontSize: '14px',
                                            width: '80%',
                                        }}>
                                            {
                                                getAddressString(address)
                                            }
                                        </div>
                                        <div style={{
                                            fontSize: '14px',
                                        }}>+91 {address?.mobile}</div>

                                        <button style={{
                                            backgroundColor: '#13c519',
                                            color: 'white',
                                            padding: '8px 20px',
                                            borderRadius: '20px',
                                            border: 'none',
                                            outline: 'none',
                                            cursor: 'pointer',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            width: '100%',
                                            marginTop: '10px',
                                        }}
                                            onClick={() => handleDeliverHere(address)}
                                        >Deliver Here
                                        </button>

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            gap: '10px',
                                            position: 'absolute',
                                            right: '10px',
                                            top: '10px',
                                        }}>
                                            <Edit style={{
                                                right: '10px',
                                                top: '10px',
                                                cursor: 'pointer',
                                                color: '#13c519',
                                            }}
                                                onClick={() => {
                                                    setShowAddNewAddress(true);
                                                    setAddress(address);
                                                }}
                                            />

                                            <Delete style={{
                                                right: '10px',
                                                top: '40px',
                                                cursor: 'pointer',
                                                color: 'red',
                                            }}
                                                onClick={() => handleDeleteAddress(address)}
                                            />
                                        </div>

                                    </div>

                                ))
                            }
                        </div>
                    </>
                }

                {/* add new address */}

                {
                    showAddNewAddress &&
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: '10px',
                        padding: '20px 10px',
                        backgroundColor: '#ffffff',
                        borderRadius: '10px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}>
                        <div style={{
                            position: 'relative',
                            display: 'flex',
                            width: '100%',
                        }}>
                            <div style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}>Add New Address</div>

                            <Close style={{
                                position: 'absolute',
                                right: '10px',
                                cursor: 'pointer',
                                color: '#13c519',
                                fontSize: '30px',
                            }}
                                onClick={() => setShowAddNewAddress(false)}
                            />
                        </div>

                        <TextField className="address_input" label="Full name *" variant="standard" value={address.fullName} onChange={(e) => handleOnChangeAddress(e, 'fullName')} />

                        <TextField className="address_input" label="Mobile number *" variant="standard" value={address.mobile} onChange={(e) => handleOnChangeAddress(e, 'mobile')} />

                        <TextField className="address_input" label="Pincode *" variant="standard" value={address.pincode} onChange={(e) => handleOnChangeAddress(e, 'pincode')} />

                        <TextField className="address_input" label="Address *" variant="standard" value={address.address} onChange={(e) => handleOnChangeAddress(e, 'address')} />

                        <TextField className="address_input" label="City/District/Town *" variant="standard" value={address.cityDistrictTown} onChange={(e) => handleOnChangeAddress(e, 'cityDistrictTown')} />

                        <TextField className="address_input" label="Locality *" variant="standard" value={address.locality} onChange={(e) => handleOnChangeAddress(e, 'locality')} />

                        <TextField className="address_input" label="State *" variant="standard" value={address.state} onChange={(e) => handleOnChangeAddress(e, 'state')} />

                        <TextField className="address_input" label="Landmark (Optional)" variant="standard" value={address.landmark} onChange={(e) => handleOnChangeAddress(e, 'landmark')} />

                        <TextField className="address_input" label="Alternate Phone (Optional)" variant="standard" value={address.alternatePhone} onChange={(e) => handleOnChangeAddress(e, 'alternatePhone')} />

                        <button style={{
                            backgroundColor: '#13c519',
                            color: 'white',
                            padding: '8px 20px',
                            borderRadius: '20px',
                            border: 'none',
                            outline: 'none',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            width: '100%',
                            marginTop: '10px',
                        }}
                            onClick={() => saveAddress()}
                        // disabled={cartItems.length === 0}
                        >Save Address
                        </button>

                    </div>
                }



            </div>
        </div>
    )
}

export default Addresses


import { useState } from "react";
import HomeHeader from "../../Components/HomeHeader"

const RefundPolicy = () => {

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 769);
    return (
        <div>
            <HomeHeader pageName={'RefundPolicy'} />

            <div style={{
                padding: isMobileView ? '10px 10px' : '20px 50px',
                fontSize: isMobileView ? '12px' : '16px',
            }}>
                <div style={{
                    fontSize: isMobileView ? '20px' : '30px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    textAlign: 'center',
                    position: 'relative',
                    fontFamily: 'rockwell_bold',
                }}>
                    Return & Refund Policy
                </div>

                Interpretation and Definitions<br /><br />
                Interpretation<br />
                Capitalized terms in this Refund Policy have meanings under specific conditions. These
                definitions apply whether the terms appear in singular or plural form.<br /><br />
                Definitions<br />
                For the purposes of this Return and Refund Policy:<br />
                - Friends of Turtles (referred to as either “the Company,” “We,” “Us,” or “Our” in this
                Agreement) refers to Bamboo India.<br />
                - Goods refer to items offered for sale on the Service.<br />
                - Orders mean a request by You to purchase Goods from Us.<br />
                - Service refers to the Website.<br />
                - Website accessible from www.fof-turtles.com<br />
                - You means the individual accessing or using the Service, or the company, or other legal
                entity on behalf of which such an individual is accessing or using the Service, as
                applicable.<br />
                Cancellation Rights<br /><br />
                Placed order can be cancelled before the shipping of the product. Cancellation before the
                shipping will attract the 10% of the order value as a cancellation charges and rest of the
                amount will be refunded in 7 working days.<br />
                You must inform us of your decision by means of a clear statement why you are cancelling
                this product and request for replace or refund.<br /><br />
                A Return policy<br />
                In order for the Goods to be eligible for a return, please make sure that:<br />
                · The Goods were purchased in the last 7 days<br />
                · The Goods are in the original packaging and not damaged by any means.<br />
                You must inform us of your decision by means of a clear statement why you are cancelling
                this product and request for replace or refund. You can inform us of your decision by:<br />
                By email: fof.turtles@gmail.com<br />
                You will be the responsible for the cost and risk of returning the Goods to us.<br /><br />
                Refund Policy<br />
                Refund process will be initiated only after receiving the product.<br />
                Inspection of the product will be conducted by our team and the amount of refund will be
                communicated via email. Upon acceptance or response to that email, in next 7 working
                days you will receive a refund to the provided bank/UPI details. We will not be responsible
                for the wrong bank/UPI details.<br />

            </div>
        </div>
    )
}

export default RefundPolicy
import { useSelector } from "react-redux";
import { loaderGif, product1 } from "../common/images"
import { useState } from "react";


const CustomLoader = () => {

    const { isLoading } = useSelector(state => state.globalReducer);

    const [mobileView, setMobileView] = useState(window.innerWidth < 769);

    return (
        <>
            {
                isLoading &&
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999,
                    userSelect: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                }}>
                    <img src={loaderGif} alt="loader" style={{
                        width: mobileView ? '130px' : '200px',
                        height: mobileView ? '130px' : '200px',

                    }} />
                    <div style={{
                        color: '#fff',
                        fontSize: '20px',
                    }}>
                        Conservation is in progress...
                    </div>
                </div>
            }
        </>
    )
}

export default CustomLoader
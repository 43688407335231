import { useEffect, useState } from "react";
import axios from "axios";
import { auth, db } from "../../Config/firebase";
import { useSearchParams } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { setIsLoading } from "../../Redux/globalReducer";
import { useDispatch } from "react-redux";

const Payment = ({ allOrderDetailsForPayment }) => {

    const [orderDetails, setOrderDetails] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(setIsLoading(true));


        let urlOrderDetails = allOrderDetailsForPayment

        if (urlOrderDetails) {
            setOrderDetails(urlOrderDetails);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(orderDetails).length > 0) {
            renderRezorpay();
        }
    }, [orderDetails]);


    const handlePayment = async () => {

        let amount = orderDetails.totalAmount;

        console.log('amount', amount);


        const orderUrl = `https://asia-south1-foft-prod.cloudfunctions.net/fotApi/createOrder`;
        const response = await axios.post(orderUrl, { amount, currency: 'INR' });
        const { data } = response;
        const { order_id: order_id, currency: orderCurrency, amount: orderAmount } = data;

        const site_url = window.location.origin;
        const callback_url = 'https://asia-south1-foft-prod.cloudfunctions.net/fotApi/verifyPayment?user_id=' + auth.currentUser.uid + '&site_url=' + site_url + '&orderDetails=' + JSON.stringify(orderDetails);


        console.log(orderDetails);

        const options = {
            // key: 'rzp_test_Ev4PNOLjFNFeT0',
            key: 'rzp_live_xofWLIV095FDAU',
            currency: 'INR',
            amount: orderAmount * 100,
            order_id: order_id,
            name: "Friends of Turtles",
            description: "Test Transaction",
            callback_url: callback_url,
            prefill: {
                name: "samir bhalerao",
                email: "test@gmail.com",
                contact: "9999999999"
            },
            notes: {
                user_id: auth.currentUser.uid,
            },
            theme: {
                color: "#3399cc"
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();

        dispatch(setIsLoading(false));

    }

    const renderRezorpay = async () => {
        handlePayment();
    }

    return (
        <div>

        </div>
    )

}


export default Payment;

import React from 'react';
import './OurTeam.scss';
import HomeHeader from '../../Components/HomeHeader';
import { sachin_sir, shrikant_sir } from '../../common/images';

function OurTeam({ homeHeader = true }) {


    return (
        <>

            {
                homeHeader &&
                <HomeHeader />
            }

            <div className='our_team_wrapper' id='our_team'>
                <div className="garden_team_area snipcss-k56tn">
                    <div className="container">
                        <div className="row" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap'
                        }}>
                            <div className="col-lg-12">
                                <div className="witr_section_title">
                                    <div className="witr_section_title_inner text-center ">
                                        <h2>MEET</h2>
                                        <h3 className='section_title_big back_gradient_anim_text'>Our Core Team</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="cons_singleTeam all_color_team witr_team_s12">
                                    <div className="front_view">
                                        <img
                                            src={shrikant_sir}
                                            alt="image"
                                        />
                                        <div className="all_content_bg_color front_view_box">
                                            <h5>Dr. Shrikant Mukate</h5>
                                            <span>Founder & CEO </span>
                                            <div className="all_team_s_color con_TMS">
                                                <a href="https://www.facebook.com/shri8669006069"
                                                    target='_blank'
                                                >
                                                    <i className="icofont-facebook" />
                                                </a>

                                                <a href="https://www.linkedin.com/in/dr-shrikant-mukate-16405825/" target='_blank'>
                                                    <i className="icofont-linkedin" />
                                                </a>
                                                <a href="https://x.com/drmukate" target='_blank'>
                                                    <i className="icofont-x" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="cons_singleTeam all_color_team witr_team_s12">
                                    <div className="front_view">
                                        <img
                                            src={sachin_sir}
                                            alt="image"
                                        />
                                        <div className="all_content_bg_color front_view_box">
                                            <h5>Mr. Sachin Shinde</h5>
                                            <span>COO </span>
                                            <div className="all_team_s_color con_TMS">
                                                <a href="https://www.facebook.com/p9822634408" target='_blank'>
                                                    <i className="icofont-facebook" />
                                                </a>
                                                {/* linkedIn*/}
                                                <a href="https://www.linkedin.com/in/sachin-shinde-35a7b4252/" target='_blank'>
                                                    <i href="#" class="icofont-linkedin"></i>
                                                </a>
                                                {/* instagram */}
                                                <a href="https://www.instagram.com/sachin_shinde_4408/" target='_blank'>
                                                    <i href="#" class="icofont-instagram"></i>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>


    )
}

export default OurTeam
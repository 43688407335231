import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './globalReducer'

const store = configureStore({
  reducer: {
    globalReducer: globalReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export default store
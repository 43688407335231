import { createUserWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth, db } from "../../Config/firebase";
import { doc, setDoc } from "firebase/firestore";
import { getCodeWiseMessage } from "../../common/commonFunctions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const Register = () => {

    const navigate = useNavigate();

    const [registerError, setRegisterError] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_no: '',
        password: '',
        confirm_password: '',
        firebase_error: '',
    });

    const [registerData, setRegisterData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_no: '',
        password: '',
        confirm_password: '',
    });

    const [firstRegisterClick, setFirstRegisterClick] = useState(false);

    useEffect(() => {
        if (firstRegisterClick) {
            isValidateData();
        }
    }, [registerData]);


    const handleRegister = () => {
        setFirstRegisterClick(true);
        console.log('Register Data: ', registerData);
        const isValidate = isValidateData();

        if (isValidate) {
            console.log('Register Data: ', registerData);

            createUserWithEmailAndPassword(auth, registerData.email, registerData.password)
                .then(async (userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    console.log('User: ', user);
                    // ...

                    // set data in firestore

                    setDoc(doc(db, "Users", user.uid), {
                        first_name: registerData.first_name ? registerData.first_name : '',
                        last_name: registerData.last_name ? registerData.last_name : '',
                        email: registerData.email ? registerData.email : '',
                        mobile_no: registerData.mobile_no ? registerData.mobile_no : '',
                        user_id: user.uid,
                        created_at: new Date().getTime(),
                    }).then(() => {
                        console.log('Document successfully written!');
                        setFirstRegisterClick(false);
                        setRegisterData({
                            first_name: '',
                            last_name: '',
                            email: '',
                            mobile_no: '',
                            password: '',
                            confirm_password: '',
                        })
                        setRegisterError({
                            first_name: '',
                            last_name: '',
                            email: '',
                            mobile_no: '',
                            password: '',
                            confirm_password: '',
                            firebase_error: '',
                        })


                        auth.signOut();

                        toast.success('Register successfully, Please login to continue.');

                        navigate('/login');

                    }).catch((error) => {
                        console.error("Error writing document: ", error);
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    console.log('Error Code: ', errorCode);
                    const errorMessage = getCodeWiseMessage(errorCode);
                    console.log('Error Message: ', errorMessage);
                    setRegisterError({
                        ...registerError,
                        firebase_error: errorMessage
                    })
                    // ..
                });
        } else {
            console.log('Register Error: ', registerError);
        }

    }


    const isValidateData = () => {
        let errors = {};

        if (registerData.first_name === '') {
            errors = {
                ...errors,
                first_name: 'First name is required'
            };
        }

        if (registerData.last_name === '') {
            errors = {
                ...errors,
                last_name: 'Last name is required'
            };
        }

        if (registerData.email === '') {
            errors = {
                ...errors,
                email: 'Email is required'
            };
        }

        if (registerData.mobile_no === '') {
            errors = {
                ...errors,
                mobile_no: 'Mobile no is required'
            };
        }

        if (registerData.password === '') {
            errors = {
                ...errors,
                password: 'Password is required'
            };
        }

        if (registerData.confirm_password === '') {
            errors = {
                ...errors,
                confirm_password: 'Confirm password is required'
            };
        }

        if (registerData.password !== registerData.confirm_password) {
            errors = {
                ...errors,
                confirm_password: 'Password and confirm password should be same'
            };
        }

        setRegisterError(errors);

        // Return true if there are no errors, otherwise false
        return Object.keys(errors).length === 0;
    };



    const handleInputChange = (e, field) => {

        setRegisterData({
            ...registerData,
            [field]: e.target.value
        })
    }



    return (
        <div className="login_screen">
            <div className="site_title">
                FRIENDS OF TURTLES
            </div>

            <div className="login_container">
                <div className="login_title">
                    Register
                </div>

                <div className="login_form">
                    <div className="login_form_field">
                        <label>First Name</label>
                        <input type="text" placeholder="Enter first name" onChange={(e) => handleInputChange(e, 'first_name')}
                            value={registerData.first_name}
                        />
                        <div className="error_message">{registerError.first_name}</div>
                    </div>
                    <div className="login_form_field">
                        <label>Last Name</label>
                        <input type="text" placeholder="Enter last name" onChange={(e) => handleInputChange(e, 'last_name')}
                            value={registerData.last_name}
                        />
                        <div className="error_message">{registerError.last_name}</div>
                    </div>
                    <div className="login_form_field">
                        <label>Email</label>
                        <input type="email" placeholder="Enter email" onChange={(e) => handleInputChange(e, 'email')}
                            value={registerData.email}
                        />
                        <div className="error_message">{registerError.email}</div>
                    </div>
                    <div className="login_form_field">
                        <label>Mobile</label>
                        <input type="number" placeholder="Enter mobile" onChange={(e) => handleInputChange(e, 'mobile_no')}
                            maxLength={10}
                        />
                        <div className="error_message">{registerError.mobile_no}</div>
                    </div>
                    <div className="login_form_field">
                        <label>Password</label>
                        <input type="password" placeholder="Enter password" onChange={(e) => handleInputChange(e, 'password')}
                            value={registerData.password}
                        />
                        <div className="error_message">{registerError.password}</div>
                    </div>
                    <div className="login_form_field">
                        <label>Confirm Password</label>
                        <input type="password" placeholder="Enter password" onChange={(e) => handleInputChange(e, 'confirm_password')}
                            value={registerData.confirm_password}
                        />
                        <div className="error_message">{registerError.confirm_password}</div>
                    </div>

                    <div className="error_message">
                        {registerError.firebase_error}
                    </div>

                    <button onClick={handleRegister}>Register</button>

                    {/* not member then go to register */}

                    <div className="not_member">
                        Already a member? <a className="ml-1" href="/login">Login</a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Register;
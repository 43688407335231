

const getCodeWiseMessage = (code) => {

    switch (code) {
        case 'auth/invalid-email':
            return 'Invalid Email or Password';
        case 'auth/user-not-found':
            return 'User not found';
        case 'auth/wrong-password':
            return 'Invalid Email or Password';
        case 'auth/email-already-in-use':
            return 'Email already in use';
        case 'auth/weak-password':
            return 'Weak Password';
        default:
            return 'Something went wrong';
    }

}


export {
    getCodeWiseMessage
}



import { useState } from "react";
import HomeHeader from "../../Components/HomeHeader"

const TermsOfSales = () => {

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 769);
    return (
        <div>
            <HomeHeader pageName={'PrivacyPolicy'} />

            <div style={{
                padding: isMobileView ? '10px 10px' : '20px 50px',
                fontSize: isMobileView ? '12px' : '16px',
            }}>
                <div style={{
                    fontSize: isMobileView ? '20px' : '30px',
                    fontWeight: 'bold',
                    marginBottom: '20px',
                    textAlign: 'center',
                    position: 'relative',
                    fontFamily: 'rockwell_bold',
                }}>
                    Terms and Conditions of Sale
                </div>

                Welcome to Friends of Turtles. These terms and conditions outline the rules and
                regulations for the sale of products through our online and offline channels.<br /><br />
                By purchasing products from Friends of Turtles, you agree to accept and adhere to these
                terms and conditions. Please refrain from making any purchases if you do not agree to all
                the terms and conditions stated on this page.<br /><br />
                1. Product Information<br />
                We make every effort to provide accurate and detailed information about our products,
                including descriptions, images, and specifications. However, we do not guarantee the
                accuracy, completeness, reliability, or suitability of the information. Please contact us if
                you have any specific inquiries about our products before making a purchase.<br /><br />
                2. Orders and Payments<br />
                By placing an order with us, you confirm that the information provided is accurate,
                complete, and current. We reserve the right to refuse or cancel any orders at our
                discretion. All payments are to be made through the provided payment methods on our
                website or at our physical store.<br /><br />
                3. Pricing and Availability<br />
                Product prices are subject to change without notice. We strive to ensure that all products
                listed on our website are available. However, availability may vary, and we reserve the
                right to discontinue or modify products at any time without prior notice.<br /><br />
                4. Shipping and Delivery<br />
                Shipping costs and estimated delivery times are provided during the checkout process.
                We aim to dispatch orders promptly; however, delivery times may vary depending on
                your location, weather conditions, traffic congestions and any other unseen situations.
                We will try our best to update you regarding the delays if we come to know in advance,
                but we are not responsible for any delays or issues caused by third-party shipping
                carriers.<br /><br />
                5. Returns and Refunds<br />
                Our return and refund policy is outlined separately on our website. Please review this
                policy to understand the conditions and process for returning products and seeking
                refunds.<br /><br />
                6. Product Warranty<br />
                We stand by the quality of our products. If you encounter any issues with a product,
                please contact us promptly. Product warranties, if applicable, will be detailed in the
                product documentation.<br /><br />
                7. Intellectual Property<br />
                All content on our website, including images, logos, and product descriptions, is the
                property of Friends of Turtles and is protected by intellectual property laws.
                Unauthorized use of any content from our website is strictly prohibited.<br /><br />
                8. Governing Law<br />
                These terms and conditions are governed by and construed in accordance with the laws
                of the Republic of India. Any disputes arising from the use of our website or the purchase
                of products shall be subject to the exclusive jurisdiction of the courts in India.<br /><br />
                9. Amendments to Terms<br />
                We reserve the right to update, modify, or replace any part of these terms and conditions
                at our discretion. It is your responsibility to check our website periodically for changes.
                Your continued use of our website or purchase of products following the posting of any
                changes constitutes acceptance of those changes.<br /><br />
                Last Updated: 1 January 2024<br />
                Thank you for choosing Friends of Turtles. We appreciate your business and look
                forward to serving you.

            </div>
        </div>
    )
}

export default TermsOfSales
import React, { useEffect, useState } from 'react';
import './MyOrders.scss';
import { product1 } from '../../common/images';
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { auth, db } from '../../Config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HomeHeader from '../../Components/HomeHeader';
import { setIsLoading } from '../../Redux/globalReducer';
import { toast } from 'react-toastify';

const MyOrders = () => {

    const dispatch = useDispatch();

    const [myOrders, setMyOrders] = useState([]);

    const { userInfo } = useSelector(state => state.globalReducer);
    const navigate = useNavigate();

    const [allProducts, setAllProducts] = useState([]);

    useEffect(() => {
        // get my orders

        dispatch(setIsLoading(true));


        if (userInfo.user_id) {

            const q = query(collection(db, "Orders"), where("user_id", "==", userInfo.user_id));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let orders = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    orders.push(doc.data());
                });

                // sort by created_at
                orders.sort((a, b) => b.created_at - a.created_at);

                console.log('orders', orders);
                setMyOrders(orders);

                dispatch(setIsLoading(false));

            });

            return () => {
                unsubscribe();
            }

        }

    }, [userInfo.user_id]);

    useEffect(() => {
        // get all images for all products

        if (allProducts.length == 0) {
            // get all products

            let allProducts = [];

            getDocs(collection(db, "Products")).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    allProducts.push(doc.data());
                });

                console.log('allProducts', allProducts);
            });

            setAllProducts(allProducts);
        }
    }, []);

    const convertToDtae = (timestamp) => {
        let date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    const handlebuyAgain = (order) => {
        console.log('order', order);

        if (order?.order_details?.orderProducts) {
            navigate('/review?details=' + JSON.stringify(order?.order_details?.orderProducts));
        }
    }

    const cancelOrder = (order) => {
        console.log('order', order);
    }

    const goToProductDetails = (product) => {
        console.log('product', product);
        navigate('/product-details?id=' + product.id);
    }

    const getProductImage = (id) => {
        console.log('id', id);
        let product = allProducts.find(product => product.id == id);
        console.log('product', product);
        if (product && product.images) {
            return product.images[0];
        }
        return product1;
    }

    const trackOrder = (order) => {
        console.log('order', order);

        if (order.track_url) {
            window.open(order.track_url, '_blank');
        } else {
            toast.error('Something went wrong while tracking order');
        }
    }

    return (
        <>
            <HomeHeader pageName={'my-orders'} />
            <div className='my_orders_screen'>
                <div className='my_order_title'>
                    My Orders
                </div>

                <div className='my_order_list_wrapper'>
                    {
                        myOrders.map((order, index) => (
                            <div className='my_order_card'>
                                <div className='my_order_card_header'>
                                    <div className='item_wrapper'>
                                        <div className='my_order_key'>
                                            Ordered on
                                        </div>
                                        <div className='my_order_value'>
                                            {convertToDtae(order.created_at)}
                                        </div>
                                    </div>
                                    <div className='item_wrapper'>
                                        <div className='my_order_key'>
                                            Total
                                        </div>
                                        <div className='my_order_value'>
                                            ₹ {order?.order_details?.totalAmount}
                                        </div>
                                    </div>
                                    <div className='item_wrapper'>
                                        <div className='my_order_key'>
                                            Order ID
                                        </div>
                                        <div className='my_order_value'>
                                            {order?.order_id}
                                        </div>
                                    </div>
                                    <div className='item_wrapper'>
                                        <div className='my_order_key'>
                                            Status
                                        </div>
                                        <div className='my_order_value' style={{
                                            textTransform: 'capitalize'
                                        }}>
                                            {order?.order_status}
                                        </div>
                                    </div>

                                    <div className='item_wrapper'>
                                        {
                                            order?.order_status == 'in-transit' &&
                                            <div className='track_order_button' onClick={() => trackOrder(order)}>
                                                Track Order
                                            </div>
                                        }
                                    </div>


                                </div>
                                <div className='my_order_card_body'>
                                    {
                                        order.order_details.orderProducts.map((product, index) => (
                                            <div className='order_details'>
                                                <div className='order_image'>
                                                    <img src={getProductImage(product.id)} alt='order' onClick={() => goToProductDetails(product)} />
                                                </div>
                                                <div className='order_info'>
                                                    <div className='order_name'>
                                                        {product.name}
                                                    </div>
                                                    <div className='order_price'>
                                                        {product.sell_price}
                                                        <span className='actual_price'>
                                                            {product.actual_price}
                                                        </span>
                                                    </div>
                                                    <div className='order_quantity'>
                                                        Quantity: {product.quantity}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    <div className='order_buttons_wrapper'>
                                        <div className='order_button' onClick={() => handlebuyAgain(order)}>
                                            Buy Again
                                        </div>
                                        {/* <div className='order_cancel_button' onClick={() => cancelOrder(order)}>
                                            Cancel Order
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                        )
                        )
                    }
                </div>
                {
                    myOrders.length == 0 &&

                    <div className='no_orders'>
                        No orders placed yet

                        <div className='shop_now_button' onClick={() => navigate('/')}>
                            Shop Now
                        </div>

                    </div>
                }
            </div >
        </>
    );
}

export default MyOrders;
import React, { useEffect, useState } from 'react'
import './BestSellingProducts.scss'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
function BestSellingProducts() {

    const navigate = useNavigate();


    const { allCategories, allProducts } = useSelector(state => state.globalReducer);

    const [categories, setCategories] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('oral_care');
    const [selectedCategoryProducts, setSelectedCategoryProducts] = useState(null);


    useEffect(() => {
        // sort the categories by the sequence number
        if (allCategories && allProducts) {
            let sortedCategories = [...allCategories].sort((a, b) => a.sequence - b.sequence);

            // create a new array with productsCount for each category
            let updatedCategories = sortedCategories.map((cat) => {
                return {
                    ...cat,
                    productsCount: [...allProducts].filter((product) => product.category_ref === cat.category_ref).length
                };
            });

            setCategories(updatedCategories);
        }
    }, [allCategories, allProducts]);

    const goToProductDetails = (product) => {
        navigate('/product-details?id=' + product.id)
    }


    useEffect(() => {
        if (selectedCategory) {
            // filter the products based on the selected category
            let filteredProducts = allProducts.filter((product) => product.category_ref === selectedCategory);

            setSelectedCategoryProducts(filteredProducts);

        }
    }, [selectedCategory])


    const handleVieAllProducts = () => {
        console.log('View All Products')

        // navigate to the products listing page
        navigate('/categories-listing')
    }

    return (
        <section className='product_categories_wrapper'>
            <div className="text-center">
                <h2 className="section_title_small">Best Selling Products</h2>
                <h3 className="section_title_big back_gradient_anim_text">Shop By Categories</h3>
            </div>

            <div className="cat_tab_wrapper">

                {
                    categories?.slice(0, 5).map((cat, index) => {
                        return (
                            <div key={index} className={`cat_box ${selectedCategory === cat.category_ref ? 'active' : ''}`}
                                onClick={() => setSelectedCategory(cat.category_ref)}
                            >
                                <img className="cat_img" src={cat?.category_image} alt="" />
                                <span className="cat_title">{cat?.category_name}</span>
                                <span className="cat_text">{cat?.productsCount} Products</span>
                            </div>
                        )
                    })
                }
            </div>

            <div class="cat_content_wrapper">
                {
                    selectedCategoryProducts?.slice(0, 4).map((product, index) => {
                        return (
                            <div class="product_card" onClick={() => goToProductDetails(product)}>
                                <div class="product_img">
                                    <img src={
                                        product?.images?.length > 0 ? product?.images[0] : 'https://via.placeholder.com/150'
                                    } alt="" srcset="" />
                                </div>
                                <div class="product_details">

                                    <div class="product_rating_box text-center">
                                        <i class="icofont-star highlight_green"></i>
                                        <i class="icofont-star highlight_green"></i>
                                        <i class="icofont-star highlight_green"></i>
                                        <i class="icofont-star highlight_green"></i>
                                        <i class="icofont-star highlight_green"></i>
                                        (5.0)
                                    </div>

                                    <div class="product_title">
                                        {product?.name}
                                    </div>

                                    <div className="product_bottom_wrapper">
                                        <div class="product_price">
                                            <div class="actual_price">
                                                ₹ {product?.sell_price}</div>
                                            <div class="mrp_price">₹ {
                                                product?.actual_price
                                            }</div>
                                        </div>
                                        {/* buy now button */}
                                        <button className='buy_now_btn' onClick={() => goToProductDetails(product)}>
                                            Buy Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
            {/* view all products button */}
            <div class="view_all_products">
                <button className='view_all_btn' onClick={() => handleVieAllProducts()}>
                    View All Products
                </button>
            </div>

        </section >

    )
}

export default BestSellingProducts
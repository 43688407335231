import { useEffect, useState } from "react";
import './Login.scss';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../Config/firebase";
import { getCodeWiseMessage } from "../../common/commonFunctions";
import { useNavigate } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();

    const [redirectUrl, setRedirectUrl] = useState('');

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const [loginErrors, setLoginErrors] = useState({
        email: '',
        password: '',
        firebase_error: '',
    });

    const [firstLoginClick, setFirstLoginClick] = useState(false);

    useEffect(() => {
        // get redirect url from url
        const urlParams = new URLSearchParams(window.location.search);
        const redirectUrl = urlParams.get('redirectUrl');
        console.log('redirectUrl', redirectUrl);

        if (redirectUrl) {
            setRedirectUrl(redirectUrl);
        }

        if (firstLoginClick) {
            isValidateData();
        }
    }, [loginData]);

    const handleInputChange = (e, field) => {
        setLoginData({
            ...loginData,
            [field]: e.target.value,
        });
    }



    const isValidateData = () => {
        let errors = {};

        if (loginData.email === '') {
            errors.email = 'Email is required';
        }

        if (loginData.password === '') {
            errors.password = 'Password is required';
        }

        setLoginErrors(errors);

        console.log('errors', errors);

        // Return true if there are no errors, otherwise false
        return Object.keys(errors).length === 0;
    }

    const handleLogin = () => {
        console.log('loginData', loginData);
        setFirstLoginClick(true);
        if (isValidateData()) {

            signInWithEmailAndPassword(auth, loginData.email, loginData.password)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    console.log('user', user);
                    // ...

                    //go to home page after login
                    // check redirectUrl from url and redirect to that page

                    if (redirectUrl) {
                        navigate(redirectUrl);
                    } else {
                        navigate('/');
                    }

                }).catch((error) => {
                    const errorCode = error.code;
                    console.log('errorCode', errorCode);

                    const errorMessage = getCodeWiseMessage(errorCode);
                    console.log('errorMessage', errorMessage);
                    setLoginErrors({
                        ...loginErrors,
                        firebase_error: errorMessage,
                    });
                });
        }
    }

    return (
        <div className="login_screen">
            <div className="site_title">
                FRIENDS OF TURTLES
            </div>

            <div className="login_container">
                <div className="login_title">
                    Login
                </div>

                <div className="login_form">
                    <div className="login_form_field">
                        <label>Email</label>
                        <input type="email" placeholder="Enter email" onChange={(e) => handleInputChange(e, 'email')}
                            value={loginData.email}
                        />
                        <div className="error_message">{loginErrors.email}</div>
                    </div>
                    <div className="login_form_field">
                        <label>Password</label>
                        <input type="password" placeholder="Enter password" onChange={(e) => handleInputChange(e, 'password')}
                            value={loginData.password}
                        />
                        <div className="error_message">{loginErrors.password}</div>
                    </div>
                    <div className="error_message">{loginErrors.firebase_error}</div>

                    <button onClick={handleLogin}>Login</button>
                    {/* not member then go to register */}
                    <div className="not_member">
                        Not a member? <a className="ml-1" href="/register">Register</a>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Login;